<template>

    <div class="h-full flex flex-col relative overflowx-hidden pb-6">

        <swiper ref="mySwiper" :options="swiperOptions" class="h-full w-full"
            :auto-destroy="true"
            :delete-instance-on-destroy="true"
            :cleanup-styles-on-destroy="true">

            <ProjectsSLColumn @nextPage="nextPage" :sortBy="sortBy" @changeSortBy="changeSortBy"/>

            <ProjectsSMUColumn @closeSL=closeSL :sl="sl" :sortBy="sortBy"/>

        </swiper>

    </div>

</template>

<script>

const ProjectsSMUColumn= () => import('@/components/Mobile/ProjectsManagement/ProjectsSMUColumn')
const ProjectsSLColumn = () => import("@/components/Mobile/ProjectsManagement/ProjectsSLColumn");

export default {
    components: {
        ProjectsSMUColumn,
        ProjectsSLColumn
    },
    data() {
        return {
            swiperOptions: {
                slidesPerView: 'auto',
                spaceBetween: 10,
                centeredSlides: true
            },
            sl:null,
            sortBy:{ name: 'Name', value: 'name', direction: 'asc' }
        }
    },
    methods:{
        nextPage(sl){
            this.sl = sl
            this.$refs.mySwiper.$swiper.slideTo(1, 300)
        },
        closeSL(){
            this.sl = null;
            this.$refs.mySwiper.$swiper.slideTo(0, 300)
        },
        changeSortBy(option){
            this.sortBy = option
        }
    }
}
</script>

<style lang="scss" scoped>

    .swiper-pagination {
        position: absolute;
        text-align: center;
        transition: 300ms opacity;
        transform: translate3d(0, 0, 0);
        z-index: 9999999;
        top: 30px;
        left: -15px;
    }

</style>
